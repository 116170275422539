<template>
  <div id="cleaningToolsDisinfection">
    <el-dialog
      :title="cleaningToolsDisinfectionFormTitle"
      width="680px"
      :visible.sync="cleaningToolsDisinfectionDialogVisible"
      :close-on-click-modal="false"
      @close="cleaningToolsDisinfectionDialogClose"
    >
      <el-form
        ref="cleaningToolsDisinfectionFormRef"
        :model="cleaningToolsDisinfectionForm"
        :rules="cleaningToolsDisinfectionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="日期" prop="disinfectionDate">
              <el-date-picker
                v-model="cleaningToolsDisinfectionForm.disinfectionDate"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd"
                :disabled="cleaningToolsDisinfectionFormTitle !== '新增清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '修改清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '清洁工具清洁消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="类别" prop="type">
              <el-radio-group
                v-model="cleaningToolsDisinfectionForm.type"
                :disabled="cleaningToolsDisinfectionFormTitle !== '新增清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '修改清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '清洁工具清洁消毒记录详情'"
              >
                <el-radio :label="1">
                  地面类
                </el-radio>
                <el-radio :label="2">
                  非地面类
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="消毒剂名称" prop="name">
              <el-input
                v-model="cleaningToolsDisinfectionForm.name"
                placeholder="请输入消毒剂名称"
                clearable
                :disabled="cleaningToolsDisinfectionFormTitle !== '新增清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '修改清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '清洁工具清洁消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制日期" prop="preparationDate">
              <el-date-picker
                v-model="cleaningToolsDisinfectionForm.preparationDate"
                placeholder="请选择配制日期"
                value-format="yyyy-MM-dd"
                :disabled="cleaningToolsDisinfectionFormTitle !== '新增清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '修改清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '清洁工具清洁消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人" prop="operator">
              <el-input
                v-model="cleaningToolsDisinfectionForm.operator"
                placeholder="请输入操作人"
                clearable
                :disabled="cleaningToolsDisinfectionFormTitle !== '新增清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '修改清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '清洁工具清洁消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="cleaningToolsDisinfectionForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="cleaningToolsDisinfectionFormTitle !== '新增清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '修改清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '清洁工具清洁消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewerResult">
              <el-radio-group
                v-model="cleaningToolsDisinfectionForm.reviewerResult"
                :disabled="cleaningToolsDisinfectionFormTitle !== '复核清洁工具清洁消毒记录'
                  && cleaningToolsDisinfectionFormTitle !== '清洁工具清洁消毒记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cleaningToolsDisinfectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cleaningToolsDisinfectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="日期">
        <el-date-picker v-model="searchForm.disinfectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CLEAN_TOOLS_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="cleaningToolsDisinfectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.disinfectionDate">{{ scope.row.disinfectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类别">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">地面类</span>
          <span v-if="scope.row.type === 2">非地面类</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="消毒剂名称" />
      <el-table-column label="配制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.preparationDate">{{ scope.row.preparationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operator" label="操作人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CLEAN_TOOLS_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CLEAN_TOOLS_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['CLEAN_TOOLS_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="cleaningToolsDisinfectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addCleaningToolsDisinfection,
  deleteCleaningToolsDisinfection,
  updateCleaningToolsDisinfection,
  selectCleaningToolsDisinfectionInfo,
  selectCleaningToolsDisinfectionList,
  reviewCleaningToolsDisinfection
} from '@/api/produce/cleaningToolsDisinfection'

export default {
  data () {
    return {
      cleaningToolsDisinfectionDialogVisible: false,
      cleaningToolsDisinfectionFormTitle: '',
      cleaningToolsDisinfectionForm: {
        id: '',
        disinfectionDate: '',
        type: '',
        name: '',
        preparationDate: '',
        operator: '',
        remarks: '',
        reviewerResult: '',
        taskId: ''
      },
      cleaningToolsDisinfectionFormRules: {
        disinfectionDate: [{ required: true, message: '日期不能为空', trigger: ['blur', 'change']}]
      },
      cleaningToolsDisinfectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        disinfectionDate: ''
      }
    }
  },
  created () {
    selectCleaningToolsDisinfectionList(this.searchForm).then(res => {
      this.cleaningToolsDisinfectionPage = res
    })
  },
  methods: {
    cleaningToolsDisinfectionDialogClose () {
      this.$refs.cleaningToolsDisinfectionFormRef.resetFields()
    },
    cleaningToolsDisinfectionFormSubmit () {
      if (this.cleaningToolsDisinfectionFormTitle === '清洁工具清洁消毒记录详情') {
        this.cleaningToolsDisinfectionDialogVisible = false
        return
      }
      this.$refs.cleaningToolsDisinfectionFormRef.validate(async valid => {
        if (valid) {
          if (this.cleaningToolsDisinfectionFormTitle === '新增清洁工具清洁消毒记录') {
            this.cleaningToolsDisinfectionForm.id = ''
            await addCleaningToolsDisinfection(this.cleaningToolsDisinfectionForm)
          } else if (this.cleaningToolsDisinfectionFormTitle === '修改清洁工具清洁消毒记录') {
            await updateCleaningToolsDisinfection(this.cleaningToolsDisinfectionForm)
          } else if (this.cleaningToolsDisinfectionFormTitle === '复核清洁工具清洁消毒记录') {
            await reviewCleaningToolsDisinfection(this.cleaningToolsDisinfectionForm)
          }
          this.cleaningToolsDisinfectionPage = await selectCleaningToolsDisinfectionList(this.searchForm)
          this.cleaningToolsDisinfectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.cleaningToolsDisinfectionFormTitle = '新增清洁工具清洁消毒记录'
      this.cleaningToolsDisinfectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCleaningToolsDisinfection(row.id)
        if (this.cleaningToolsDisinfectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.cleaningToolsDisinfectionPage = await selectCleaningToolsDisinfectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.cleaningToolsDisinfectionFormTitle = '修改清洁工具清洁消毒记录'
      this.cleaningToolsDisinfectionDialogVisible = true
      this.selectCleaningToolsDisinfectionInfoById(row.id)
    },
    handleReview (index, row) {
      this.cleaningToolsDisinfectionFormTitle = '复核清洁工具清洁消毒记录'
      this.cleaningToolsDisinfectionDialogVisible = true
      this.selectCleaningToolsDisinfectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.cleaningToolsDisinfectionFormTitle = '清洁工具清洁消毒记录详情'
      this.cleaningToolsDisinfectionDialogVisible = true
      this.selectCleaningToolsDisinfectionInfoById(row.id)
    },
    selectCleaningToolsDisinfectionInfoById (id) {
      selectCleaningToolsDisinfectionInfo(id).then(res => {
        this.cleaningToolsDisinfectionForm.id = res.id
        this.cleaningToolsDisinfectionForm.disinfectionDate = res.disinfectionDate
        this.cleaningToolsDisinfectionForm.type = res.type
        this.cleaningToolsDisinfectionForm.name = res.name
        this.cleaningToolsDisinfectionForm.preparationDate = res.preparationDate
        this.cleaningToolsDisinfectionForm.operator = res.operator
        this.cleaningToolsDisinfectionForm.remarks = res.remarks
        this.cleaningToolsDisinfectionForm.reviewerResult = res.reviewerResult
        this.cleaningToolsDisinfectionForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCleaningToolsDisinfectionList(this.searchForm).then(res => {
        this.cleaningToolsDisinfectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCleaningToolsDisinfectionList(this.searchForm).then(res => {
        this.cleaningToolsDisinfectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCleaningToolsDisinfectionList(this.searchForm).then(res => {
        this.cleaningToolsDisinfectionPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['CLEAN_TOOLS_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
