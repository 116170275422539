import axios from '@/common/axios'
import qs from 'qs'

export function addCleaningToolsDisinfection (data) {
  return axios({
    method: 'post',
    url: '/production/cleaningToolsDisinfection/add',
    data: qs.stringify(data)
  })
}

export function deleteCleaningToolsDisinfection (id) {
  return axios({
    method: 'delete',
    url: '/production/cleaningToolsDisinfection/delete/' + id
  })
}

export function updateCleaningToolsDisinfection (data) {
  return axios({
    method: 'put',
    url: '/production/cleaningToolsDisinfection/update',
    data: qs.stringify(data)
  })
}

export function selectCleaningToolsDisinfectionInfo (id) {
  return axios({
    method: 'get',
    url: '/production/cleaningToolsDisinfection/info/' + id
  })
}

export function selectCleaningToolsDisinfectionList (query) {
  return axios({
    method: 'get',
    url: '/production/cleaningToolsDisinfection/list',
    params: query
  })
}

export function reviewCleaningToolsDisinfection (data) {
  return axios({
    method: 'put',
    url: '/production/cleaningToolsDisinfection/review',
    data: qs.stringify(data)
  })
}
